<template>
  <!-- 招标列表 -->
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <!-- 应标资格 -->
        <el-select v-model="eligibleType" placeholder="应标资格" clearable @change="onCurrentPage">
          <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 标期搜索 -->
        <el-select v-model="bidPeriod" filterable placeholder="请选择标期搜索" @change="onCurrentPage" clearable>
          <el-option v-for="item in options" :key="item.tendersId" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input placeholder="请输入标号" suffix-icon="el-icon-search" v-model="tendersLabel" clearable
          @change="handleSearch()">
        </el-input>
        <el-input placeholder="请输入标书名称" suffix-icon="el-icon-search" v-model="tendersName" clearable
          @change="handleSearch()">
        </el-input>

      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="bidPeriod" label="标期" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="tendersLabel" label="标号" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="tendersName" label="招标名称" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="createdTime" label="发布时间" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.createdTime ? scope.row.createdTime.slice(0, 10) : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tendersTime" label="开标时间" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="tendersType" label="招标类型" show-overflow-tooltip min-width="80" align="center">
            <template slot-scope="scope">
              <div>
                {{ getTypeText(scope.row.tendersType) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="eligibleType" label="应标资格" show-overflow-tooltip min-width="80" align="center">
            <template slot-scope="scope">
              <div>
                {{ getEligibleText(scope.row.eligibleType) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tendersNumber" label="最大投标商品数" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="tendersStatus" label="状态" show-overflow-tooltip min-width="100" align="center">
            <!-- 标书状态(0招标中: 1:评标中 2:已评标) -->
            <template slot-scope="scope">
              <div>
                {{ scope.row.tendersStatus == 0 ? '招标中' : scope.row.tendersStatus == 1 ? '评标中' : '已评标' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      // 招标信息数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      //应标资格
      selectData: [{
        value: '1',
        label: '平台资格'
      }, {
        value: '2',
        label: '独立标资格'
      }],
      //选择标期
      options: [],
      eligibleType: '',//应标资格
      bidPeriod: '',//标期
      tendersLabel: '',//搜索标号
      tendersName: '',//搜索标书名称
    }
  },
  created() {
    this.tendersLabel = JSON.parse(localStorage.getItem('param'))?.tl || ''//门户携带过来的标号
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productList"))
    this.bidPeriod = obj?.bidPeriod
    this.tendersName = obj?.tendersName
    this.eligibleType = obj?.eligibleType
    this.tendersLabel = obj?.tendersLabel
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("productList", {})
    // 获取列表
    this.getRequest()
    this.getBidPeriod()
  },
  destroyed() {
    localStorage.removeItem('param')
  },
  methods: {
    // 列表请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendTenderList, {
        params: {
          bidPeriod: this.bidPeriod || null,//标期
          tendersName: this.tendersName || null,//标书名称
          // tendersType: '',//招标类型
          eligibleType: this.eligibleType || null,//1、平台资格，2独立版资格
          tendersLabel: this.tendersLabel || null,//标号
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    // 标期列表请求
    getBidPeriod() {
      this.$axios.get(this.$api.sendBidPeriodPage, {
        params: {
          // bidPeriod: this.bidPeriod,//标期
          page: this.currentPage,
          pageSize: 99999,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          // console.log('标期', result.list);
          this.options = result.list.map((item) => {
            return {
              tendersId: item.tendersId, // 假设你想要保留tendersId 
              value: item.bidPeriod,
              label: item.bidPeriod
            };
          });

          this.loading = false;

        }
      }).catch(() => {
        // console.log(err);
        this.loading = false;
      })

    },
    // 级联选择搜索
    handleChange(val) {
      this.tableData = []
      this.bidPeriod = val
      // console.log('级联选择', val);
      this.getRequest()
    },
    // 标号、回车搜索
    handleSearch() {
      if (this.tendersLabel == '') localStorage.removeItem('param')
      this.tableData = []
      this.getRequest()
    },
    // 分页器
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getRequest()
    },
    // 跳转详情
    handleCommand(row) {
      let obj = {
        bidPeriod: this.bidPeriod,
        tendersName: this.tendersName,
        eligibleType: this.eligibleType,
        tendersLabel: this.tendersLabel,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productList", obj)

      this.$router.push({ name: 'bidList', query: { label: row.tendersLabel } })
      // console.log(row);
    },
    // 状态文字显示
    getTypeText(status) {
      switch (status) {
        case '1':
          return '商超';
        default:
          return '-';
      }
    },
    getEligibleText(status) {
      switch (status) {
        case '1':
          return '平台资格';
        default:
          return '独立标资格';
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    // 头部+搜索
    header {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
    }
  }

  .tableBox {
    overflow: auto;


    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }

  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

}
</style>
